<template>
  <div class="footer-container">
    <div class="footer">
      <img src="../assets/img/logo.png" alt="" />
      <div>
        <p></p>
        <p>
          <span @click="handleLink" >
            <router-link :to="{path:'/childerGuard'}" class="childer-page" >成长关爱</router-link>
          </span>
          
           | COPYRIGHT © 2023 上海鹿深科技有限公司
          <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #888888;"> 沪ICP备2022009975号-1</a> 
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202014701" class='footer-put-on-records' style="display: flex;align-items: center;">
            <img src="../assets/img/footer-icon.png" class='footer-icon' style="width: 20px; height: 20px;margin-right: 6px;" /> 
            <p >沪公网安备 31011202014701号</p>
          </a>
        </p>
        <p>公司电话：021-66079967 地址：上海市闵行区莲花路1733号4幢D106A室</p>
        <p>
          抵制不良游戏 拒绝盗版游戏 注意自我保护 谨防受骗上当 适度游戏益脑
          沉迷游戏伤身 合理安排时间 享受健康生活
        </p>
      </div>
    </div>
  </div>
</template>
    
    <script>
export default {
  data() {
    return {};
  },
  methods: {
    handleLink(){
      // console.log('dddd')
      this.$bus.$emit('setTabIndex', false)
    }
  },
};
</script>
    
    <style scoped lang="less">
.footer-container {
  height: 220px;
  width: 100%;
  background: #eeeeee;
  display: flex;
  justify-content: center;
  .childer-page{
    color: #888888;
  }
  .footer {
    width: 1000px;
    height: 100%;
    display: flex;
    align-items: center;
    img {
      width: 115px;
      height: 53px;
      user-select: none;
      margin-right: 42px;
    }
    div {
      padding-left: 45px;
      border-left: 1px solid #adadad;
      text-align: left;
      height: 76px;
      p {
        line-height: 18px;
        color: #888888;
        font-size: 12px;
      }
    }
  }
}
</style>
    
<template>
  <div class="news-container">
    <div class="news-top">
      <div class="news-title">
        <span>新闻详情</span>
      </div>
    </div>
    <div class="news-content">
      <div class="news-content-l">
        <div class="news-content-title">石器时代：致老朋友们的一封信</div>
        <div class="news-content-time">3-30</div>
        <div class="news-content-box">
          <p>亲爱的石器时代玩家，</p>
          <p>
            时光飞逝，如果从石器时代诞生之初算起，距离现在已经有23年，回想起初次相见的那个年代，在懵懂中摸索到游戏的至真乐趣，伴随我们度过了无数个青涩的日子。我们坚信，对于每一位曾经玩过石器时代的玩家，那段岁月都是无可替代的珍贵回忆。
          </p>
          <img src="../assets/img/news1.png" alt="" />
          <p>
            经过不断的努力，今天终于可以跟大家说，石器时代即将再度归来。过去的回忆充满力量，新的时代有了具体的模样，很快，我们马上就要回到那个温情欢乐的时代。经历短暂的沉寂和分别，后面是重逢的生机和喜悦。
          </p>
          <p>期待再会，以及，欢迎回家。</p>
          <img src="../assets/img/news2.png" alt="" />
        </div>
      </div>
      <div class="news-content-r">
        <div class="news-information">
          <div class="information-title">热门资讯</div>
          <div class="information-item">石器时代：致老朋友们的一封信</div>
        </div>
        <div class="news-img">
          <div>了解详情</div>
        </div>
      </div>
    </div>
  </div>
</template>
      
<script>
export default {
  name: "news",
  data() {
    return {
    };
  },
};
</script>
      
      <!-- Add "scoped" attribute to limit CSS to this component only -->
      <style scoped lang="less">
.news-container {
  min-height: calc(100vh - 296px);
  background: #ffffff;
  .news-top {
    width: 100%;
    height: 132px;
    background: url("../assets/img/recruitment-bg.png") no-repeat;
    background-size: 100% 100%;
    .news-title {
      width: 1000px;
      height: 132px;
      margin: 0 auto;
      text-align: left;
      position: relative;
      span {
        font-size: 40px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 72px;
        height: 72px;
        border-bottom: 2px solid #fed400;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }
  .news-content {
    width: 1000px;
    margin: 50px auto;
    display: flex;
    justify-content: space-between;
    .news-content-l {
      width: 710px;
      .news-content-title {
        font-size: 30px;
        font-weight: bold;
        color: #2f2f2f;
        text-align: left;
      }
      .news-content-time {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #a0a0a0;
        line-height: 34px;
        text-align: left;
        border-bottom: 1px solid #e0e0e0;
        margin-top: 16px;
        margin-bottom: 20px;
      }
      .news-content-box {
        p {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          line-height: 22px;
          text-align: left;
        }
        img {
          width: 650px;
          height: 360px;
          margin: 20px auto;
        }
      }
    }
    .news-content-r {
      margin-top: 78px;
      .news-information {
        width: 170px;
        height: 242px;
        background: #f8f9fa;
        border: 1px solid #eaeaeb;
        border-radius: 6px;
        padding: 20px;
        box-sizing: border-box;
        .information-title {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          line-height: 22px;
          margin-bottom: 10px;
          text-align: left;
        }
        .information-item {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          line-height: 26px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: left;
        }
      }
      .news-img {
        width: 170px;
        height: 222px;
        background: url("../assets/img/new-img.png") no-repeat;
        background-size: 100% 100%;
        position: relative;
        margin-top: 20px;
        div {
          width: 120px;
          height: 32px;
          background: #5aa0ff;
          border-radius: 16px;
          font-size: 16px;
          color: #ffffff;
          font-weight: 400;
          line-height: 32px;
          text-align: center;
          cursor: pointer;
          position: absolute;
          bottom: 7px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}
</style>
      
<template>
  <div class="home-container">
    <div class="banner">
      <div class="banner-title1">
        <img src="../assets/img/banner-title.png" alt="" />
      </div>
      <div class="banner-title2">
        <img src="../assets/img/banner-title2.png" alt="" />
      </div>
    </div>
    <div class="new-container">
      <div class="new-left">
        <div class="new-detail">
          <p>NEWS</p>
          <div class="new-img">
            <div>了解详情</div>
          </div>
        </div>
        <div class="new-list">
          <div class="new-list-top">
            <span>more></span>
          </div>
          <div class="new-list-text">
            <div v-for="item in 1" :key="item" class="new-list-item" @click="toNews">
              <span>石器时代：致老朋友们的一封信</span>
              <span>3-30</span>
            </div>
          </div>
        </div>
      </div>
      <div class="new-right">
        <el-carousel
          :interval="3000"
          arrow="always"
          :autoplay="true"
          indicator-position="outside"
          trigger="click"
        >
          <el-carousel-item v-for="item in carouselList" :key="item">
            <img :src="item" alt="" class="carousel-img" />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "home",
  data() {
    return {
      carouselList: [
        require("../assets/img/carousel-img.png"),
      ],
    };
  },
  methods:{
    toNews(){
        this.$router.push('/news')
    }
  }
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="less">
.home-container {
  min-height: calc(100vh - 296px);
  background: #ffffff;
  .banner {
    width: 100%;
    min-height: 600px;
    background: url("../assets/img/banner.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    user-select: none;
    .banner-title1 {
      width: 370px;
      height: 133px;
      margin-top: 60px;
      margin-bottom: 22px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .banner-title2 {
      width: 705px;
      height: 314px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .new-container {
    display: flex;
    width: 1000px;
    margin: 25px auto;
    .new-left {
      display: flex;
      .new-detail {
        p {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #343434;
          text-align: left;
        }
        .new-img {
          width: 130px;
          height: 170px;
          background: url("../assets/img/new-img.png") no-repeat;
          background-size: 100% 100%;
          position: relative;
          margin-top: 9px;
          div {
            width: 90px;
            height: 24px;
            background: #5aa0ff;
            border-radius: 12px;
            font-size: 12px;
            color: #ffffff;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            cursor: pointer;
            position: absolute;
            bottom: 6px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
      .new-list {
        margin-left: 30px;
        .new-list-top {
          width: 503px;
          border-bottom: 1px solid #e6e6e6;
          text-align: right;
          span {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #9c9ea7;
            line-height: 28px;
            cursor: pointer;
          }
        }
        .new-list-text {
          margin-top: 28px;
          .new-list-item {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            color: #878787;
            line-height: 14px;
            cursor: pointer;
            margin-bottom: 20px;
            :hover {
              color: #ed8510;
            }
          }
        }
      }
    }
    .new-right {
      margin-left: 98px;
      width: 238px;
      /deep/ .el-carousel__container {
        height: 170px;
        .el-carousel__arrow {
          display: none;
        }
        .el-carousel__item {
          img {
            width: 238px;
            height: 146px;
          }
        }
      }
      /deep/.el-carousel__indicator {
        &.is-active {
          .el-carousel__button {
            background: #b97724;
          }
        }
        .el-carousel__button {
          width: 8px;
          height: 8px;
          background: #dddcdb;
          border-radius: 50%;
        }
      }
    }
  }
}
</style>
  
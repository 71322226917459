<template>
  <div class="about-container">
    <div class="about-top">
      <div class="about-title">
        <span>关于我们</span>
      </div>
    </div>
    <div class="about-content">
      <p>
        上海鹿游网络科技有限公司是一家集游戏制作、游戏发行、游戏运营于一体的跨平台综合性游戏产品文化公司。
      </p>
      <p>由一群致力于发掘快乐的资深游戏爱好者组成。</p>
      <p>公司自主研发知名IP手游《新石器时代》即将上线。</p>
      <p>
        公司以“真诚，客观，务实”的企业精神立足根本。“真诚发现快乐”是我们的企业信条。
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "about",
  data() {
    return {
      tabName: "first",
    };
  },
};
</script>
        
<style scoped lang="less">
.about-container {
  min-height: calc(100vh - 296px);
  background: #ffffff;
  .about-top {
    width: 100%;
    height: 132px;
    background: url("../assets/img/recruitment-bg.png") no-repeat;
    background-size: 100% 100%;
    .about-title {
      width: 1000px;
      height: 132px;
      margin: 0 auto;
      text-align: left;
      position: relative;
      span {
        font-size: 40px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 72px;
        height: 72px;
        border-bottom: 2px solid #fed400;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }
  .about-content {
    width: 1000px;
    margin: 50px auto;
    p {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #2f2f2f;
      line-height: 60px;
      text-align: left;
    }
  }
}
</style>
        
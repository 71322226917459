<template>
  <div class="recruitment-container">
    <div class="recruitment-top">
      <div class="recruitment-title">
        <span>招聘信息</span>
      </div>
    </div>
    <div class="recruitment-content">
      <div class="recruitment-tsbs">
        <el-tabs v-model="tabName">
          <el-tab-pane label="招聘职位" name="first">
            <div class="tabs-content-top"><span>全部</span></div>
            <div class="tabs-content-bottom">暂无招聘信息</div>
          </el-tab-pane>
          <el-tab-pane label="校招职位" name="last">
            <div class="tabs-content-top"><span>全部</span></div>
            <div class="tabs-content-bottom">暂无招聘信息</div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
    
<script>
export default {
  name: "recruitment",
  data() {
    return {
      tabName: "first",
    };
  },
};
</script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style scoped lang="less">
.recruitment-container {
  min-height: calc(100vh - 296px);
  background: #ffffff;
  .recruitment-top {
    width: 100%;
    height: 132px;
    background: url("../assets/img/recruitment-bg.png") no-repeat;
    background-size: 100% 100%;
    .recruitment-title {
      width: 1000px;
      height: 132px;
      margin: 0 auto;
      text-align: left;
      position: relative;
      span {
        font-size: 40px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 72px;
        height: 72px;
        border-bottom: 2px solid #fed400;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }
  .recruitment-content {
    width: 1000px;
    margin: 51px auto 0;
    .recruitment-tsbs {
      width: 1000px;
      /deep/.el-tabs__nav-wrap {
        width: 1000px;
        height: 60px;
        background: #f2f5f4;
        &::after {
          background: transparent;
        }
        .el-tabs__nav {
          margin-top: 20px;
          margin-left: 50px;
          .el-tabs__active-bar {
            background: #ff9c1b;
          }
          .el-tabs__item {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #a0a0a0;
            &.is-active {
              color: #2f2f2f;
            }
          }
        }
      }
      /deep/.el-tabs__header {
        margin: 0 0 40px;
      }
      .tabs-content-top {
        margin: 0 auto;
        width: 900px;
        height: 32px;
        border-bottom: 1px solid #e0e0e0;
        text-align: left;
        span {
          display: inline-block;
          width: 86px;
          height: 32px;
          border-bottom: 2px solid #ff9c1b;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #2f2f2f;
          line-height: 32px;
          text-align: center;
        }
      }
      .tabs-content-bottom {
        margin: 20px auto;
        width: 900px;
        text-align: left;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #a0a0a0;
        line-height: 20px;
      }
    }
  }
}
</style>
    
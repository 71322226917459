<template>
  <div :class="['childer' , 'childer-' + type] ">
    <h1>鹿游游戏成长关爱系统</h1> <hr /><br />
    为保护未成年人权益，促进未成年人健康成长，根据《中华人民共和国未成年人保护法》以及国家新闻出版署《关于防止未成年人沉迷网络游戏工作的通知》、《关于进一步严格管理切实防止未成年人沉迷网络游戏的通知》等法律法规和文件要求，我们搭建了未成年人成长关爱系统。<br />
    我们为未成年用户提供家长监护服务：<br /><br />
   <strong>一、申请家长监护服务流程：</strong> <br /><br />
    <img src='../assets/img/children-flow.jpg' /><br /><br />

    <strong>二、申请条件：</strong><br />
    1.申请人及被监护未成年人均需为中华人民共和国公民；<br />
    2.申请人需为被监护未成年人的法定监护人；<br />
    3.申请人的被监护人年龄需为小于18周岁且为申请处理的游戏账号实际使用人<br /><br />

    <strong>三、申请监护需要提供以下资料：</strong><br />
    1.监护人信息表，需提供监护人的身份证明复印件；<br />
    2.被监护人信息表，需提供被监护人所玩游戏的相关信息及身份证明复印件<br />
    3.填写网络游戏未成年人家长监护申请书、保证书、授权书并手工签字<br />
    *需要身份证明信息仅为了证明申请人与申请监护服务账号所属者的关系，判断是否符合家长监护服务申请条件。<br />
    *需要账号归属资料仅为了判断申请监护账号为申请人的被监护人所有。<br /><br />

    申请表下载：<br />
    <!-- <p class="down" @click="downloadZip">点击下载申请表格</p><br /><br /><br /><br /> -->
    <a class="down" @click="downloadZip">点击下载申请表格</a><br /><br /><br /><br />


  </div>
</template>

<script>
export default {
  name: "childerGuard",
  data(){
    return {
      type:'',
    }
  },
  mounted() {
    if (/(iPhone|iPad|iPod|iOS|Android|Windows Phone|BlackBerry|SymbianOS)/i.test(navigator.userAgent)) {
      this.type = 'm'
    }else{
      this.type = 'pc'
    }
    
  },
  methods:{
    downloadZip(){
      const fileUrl = process.env.BASE_URL + 'childer.zip'; // 替换为实际的ZIP文件路径
      // const fileUrl ='http://172.16.0.178:8080/assets/childer.zip'; // 替换为实际的ZIP文件路径

        // 创建一个隐藏的<a>标签
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = fileUrl;
        link.download = '未成年监护服务申请书.zip';

        // 将<a>标签添加到DOM中
        document.body.appendChild(link);

        // 模拟点击下载
        link.click();

        // 清理DOM
        document.body.removeChild(link);
      }
  }
  
  
}
</script>

<style scoped lang="less">
.childer{
  font-size: 1.1em;
  text-align: left;
  width: 60%;
  margin: auto;
  line-height: 40px;

  h1{
    text-align: center;
  }
  img{
    width: 100%;
  }
  .down{
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }
}

.childer-m{
  width: 90%;
  // font-size: 1em;
  padding: 0;

  h1{
    // font-size: 1.6em;
  }
  
}
</style>
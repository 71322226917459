<template>
  <div id="app">
    <MyHeder> </MyHeder>
    <router-view> </router-view>
    <MyFooter> </MyFooter>
  </div>
</template>

<script>
import MyHeder from "./components/myHeder.vue";
import MyFooter from "./components/myFooter.vue";
export default {
  name: "App",
  components: {
    MyHeder,
    MyFooter
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>

<template>
  <div class="heder-container">
    <div class="heder">
      <img src="../assets/img/logo.png" alt="" />
      <ul>
        <li
          v-for="(item, index) in navList"
          :key="index"
          @click="tabNav(item, index)"
          :style="{ color: tabIndex === index ? '#ED8510' : '#393939' }"
        >
          {{ item.label }}
        </li>
        <li><a href="https://www.giraffar.com/mall/" target="_blank">官方商城</a></li>
      </ul>
    </div>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      navList: [
        { path: "/", label: "首页" },
        { path: "/recruitment", label: "人才招聘" },
        { path: "/about", label: "关于我们" },
      ],
      tabIndex: 0,
    };
  },
  methods: {
    tabNav(item, index) {
      this.tabIndex = index;
      this.$router.push(item.path);
    },
  },
  mounted(){
    this.$bus.$on('setTabIndex',(msg)=>{
      this.tabIndex = msg
    })
  }
};
</script>
  
  <style scoped lang="less">
.heder-container {
  height: 76px;
  width: 100%;
  background: #ffffff;
  display: flex;
  justify-content: center;
  a{
    color: #000;
    text-decoration: none;
  }
  .heder {
    width: 1000px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 115px;
      height: 53px;
      user-select: none;
    }
    ul {
      display: flex;
      li {
        list-style: none;
        margin-right: 40px;
        cursor: pointer;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #393939;
      }
    }
  }
}
</style>
  
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'
import Recruitment from '../views/recruitment.vue'
import News from '../views/news.vue'
import About from '../views/about.vue'
import ChilderGuard from '../views/childerGuard.vue'
Vue.use(VueRouter)
const router = new VueRouter({
    routes: [
        { path: '/', component: Home },
        { path: '/recruitment', component: Recruitment },
        { path: '/news', component: News },
        { path: '/about', component: About },
        { path: '/childerGuard', component: ChilderGuard },
    ]
})
export default router